import { ButtonProps, Button as ChakraButton } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

import { ButtonType } from '@/types';

interface Props extends ButtonProps {
  children: ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  appButtonType?: ButtonType;
}

const chooseStylingByType = (type: ButtonType): ButtonProps => {
  switch (type) {
    case ButtonType.Primary: {
      return { variant: 'solid', colorScheme: 'purple', _focus: { boxShadow: '0 0 0 3px rgba(34, 43, 69, 0.6)' } };
    }
    case ButtonType.Secondary: {
      return { variant: 'outline', colorScheme: 'gray', borderColor: 'gray.100' };
    }
    case ButtonType.Link: {
      return { variant: 'link', colorScheme: 'purple' };
    }
    case ButtonType.Ghost: {
      return {
        variant: 'ghost',
        colorScheme: 'purple',
        _active: { backgroundColor: 'gray.100' },
        _focus: { borderColor: 'transparent' },
      };
    }
  }
};

const Button: React.FC<Props> = React.forwardRef(
  ({ children, appButtonType = ButtonType.Secondary, ...props }: Props, ref): JSX.Element => {
    return (
      <ChakraButton fontWeight="600" borderRadius={100} {...chooseStylingByType(appButtonType)} {...props} {...ref}>
        {children}
      </ChakraButton>
    );
  },
);

export default Button;
