import React from 'react';

interface Props {
  color: string;
  width?: string | number;
}

const Dot: React.FC<Props> = ({ color, width = 9 }: Props) => {
  const halfWidth = Number(width) / 2;

  return (
    <svg width={width} height={width}>
      <circle r={`${halfWidth}px`} cx={`${halfWidth}px`} cy={`${halfWidth}px`} fill={color} />
    </svg>
  );
};
export default Dot;
