import { EthereumCall, InvestmentOpportunity } from '@/types';

import { PLATFORM_IDS } from '@/constants';
import cErc20Abi from '@/abis/cErc20Abi';
import cEthAbi from '@/abis/cEthAbi';
import { ethers } from 'ethers';

const COZY_RINKEBY_DAI_MARKET_ADDRESS = '0xa6ef3a6efee0221f30a43cfaa36142f6bc050c4d';
const COMPOUND_RINKEBY_DAI_INVESTMENT_CONTRACT_ADDRESS = '0x6d7f0754ffeb405d23c51ce938289d4835be3b14';

const COZY_RINKEBY_ETH_MARKET_ADDRESS = '0x13fe77e881662035e023a9a33e3e7325bda98330';
const COMPOUND_RINKEBY_ETH_INVESTMENT_CONTRACT_ADDRESS = '0xd6801a1dffcd0a410336ef88def4320d6df1883e';

export type CompoundAccountSubgraphData = {
  account: {
    tokens: { cTokenBalance: string; accrualBlockNumber: string; market: { id: string; exchangeRate: number } }[];
  };
};
export type CompoundMarketSubgraphData = {
  markets: { id: string; supplyRate: number }[];
};

export default {
  ethDepositCall: (
    investmentOpportunity: InvestmentOpportunity,
    investParam: string,
    signer: ethers.Signer,
  ): EthereumCall => {
    const { investmentContractAddress } = investmentOpportunity;

    const targetContract = new ethers.Contract(investmentContractAddress, cEthAbi, signer);

    return {
      data: targetContract.interface.encodeFunctionData('mint'),
      requireSuccess: true,
      target: investmentContractAddress,
      value: investParam,
    };
  },
  erc20DepositCall: (
    investmentOpportunity: InvestmentOpportunity,
    investParam: string,
    signer: ethers.Signer,
  ): EthereumCall => {
    const { investmentContractAddress } = investmentOpportunity;

    const targetContract = new ethers.Contract(investmentContractAddress, cErc20Abi, signer);

    return {
      data: targetContract.interface.encodeFunctionData('mint', [investParam]),
      requireSuccess: true,
      target: investmentContractAddress,
      value: 0,
    };
  },
  determineInvestRate: (
    investmentOpportunity: InvestmentOpportunity,
    subgraphData: CompoundMarketSubgraphData,
  ): number => {
    const investmentMarket = subgraphData.markets.find(
      (market) => market.id === investmentOpportunity.investmentContractAddress.toLowerCase(),
    );

    return investmentMarket.supplyRate;
  },
  determineInvestBalanceUnderlying: (
    investmentOpportunity: InvestmentOpportunity,
    subgraphData: CompoundAccountSubgraphData,
  ): number => {
    const tokens = subgraphData.account?.tokens;
    const investmentContractPosition = tokens?.find(
      (position) => position.market.id === investmentOpportunity.investmentContractAddress.toLowerCase(),
    );

    const cTokenBalance = parseFloat(investmentContractPosition?.cTokenBalance);
    const market = investmentContractPosition?.market;

    const investBalanceUnderlying = cTokenBalance * market?.exchangeRate;

    return investBalanceUnderlying;
  },
  determineLastUpdatedBlockNumber: (
    investmentOpportunity: InvestmentOpportunity,
    subgraphData: CompoundAccountSubgraphData,
  ): number => {
    const tokens = subgraphData.account?.tokens;
    const investmentContractPosition = tokens?.find(
      (position) => position.market.id === investmentOpportunity.investmentContractAddress.toLowerCase(),
    );

    const blockNumber = investmentContractPosition?.accrualBlockNumber;

    return blockNumber ? parseInt(blockNumber) : null;
  },
  withdrawCall: (
    investmentOpportunity: InvestmentOpportunity,
    withdrawParam: string,
    signer: ethers.Signer,
  ): EthereumCall => {
    const { investmentContractAddress } = investmentOpportunity;

    const targetContract = new ethers.Contract(investmentContractAddress, cErc20Abi, signer);

    return {
      data: targetContract.interface.encodeFunctionData('redeemUnderlying', [withdrawParam]),
      requireSuccess: true,
      target: investmentContractAddress,
      value: 0,
    };
  },
  investmentOpportunities: [
    {
      cozyMarketAddress: COZY_RINKEBY_DAI_MARKET_ADDRESS,
      investmentContractAddress: COMPOUND_RINKEBY_DAI_INVESTMENT_CONTRACT_ADDRESS,
      platformId: PLATFORM_IDS.Compound,
    },
    {
      cozyMarketAddress: COZY_RINKEBY_ETH_MARKET_ADDRESS,
      investmentContractAddress: COMPOUND_RINKEBY_ETH_INVESTMENT_CONTRACT_ADDRESS,
      platformId: PLATFORM_IDS.Compound,
    },
  ],
};
