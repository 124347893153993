import {
  Account,
  InvestmentOpportunity,
  InvestmentPlatform,
  Market,
  MarketTypes,
  Platform,
  Position,
  Trigger,
  TriggerCreator,
} from '@/types';

import investmentPlatforms from '@/investmentPlatforms';

export const findDefaultPlatformByTrigger = (platforms: Platform[], trigger: Trigger): Platform => {
  const triggerPlatforms = findPlatformsByTrigger(platforms, trigger);
  return triggerPlatforms?.[0];
};

export const findPlatformsByTrigger = (platforms: Platform[], trigger: Trigger): Platform[] => {
  return platforms.filter((platform) => trigger?.platformIds.includes(platform.id));
};

export const findTriggerCreatorByTrigger = (triggerCreators: TriggerCreator[], address: string): TriggerCreator => {
  return triggerCreators.find((creator) => creator.address === address);
};

export const findPositionByMarketId = (account: Account, marketId: string): Position => {
  return account?.positions?.find((position) => {
    return position.marketId === marketId;
  });
};

export const findSupplyPositions = (account: Account, marketType?: MarketTypes): Position[] => {
  return account?.positions?.filter(
    (position) => position.supplyBalanceUnderlying > 0 && marketTypeMatches(position.market, marketType),
  );
};

export const findBorrowPositions = (account: Account, marketType?: MarketTypes): Position[] => {
  return account?.positions?.filter(
    (position) => position.borrowBalanceUnderlying > 0 && marketTypeMatches(position.market, marketType),
  );
};

const marketTypeMatches = (market: Market, marketType?: MarketTypes) => {
  if (marketType === MarketTypes.ProtectionMarket && market.triggerAddress == null) {
    return false;
  } else if (marketType === MarketTypes.NonProtectionMarket && market.triggerAddress != null) {
    return false;
  }

  return true;
};

export const findInvestInfoForMarket = (
  market: Market,
): { investmentOpportunity: InvestmentOpportunity; investmentPlatform: InvestmentPlatform } => {
  const opportunities: InvestmentOpportunity[] = investmentPlatforms.flatMap(
    (platform) => platform.investmentOpportunities,
  );
  const investmentOpportunity = opportunities.find((opty) => {
    return opty.cozyMarketAddress === market.id;
  });

  const investmentPlatform = investmentPlatforms.find((platform) => {
    return (platform.investmentOpportunities as InvestmentOpportunity[])
      .map((opty) => opty?.investmentContractAddress)
      .includes(investmentOpportunity?.investmentContractAddress);
  });

  return { investmentOpportunity, investmentPlatform };
};
