import { EthereumCall, InvestmentOpportunity } from '@/types';

import BigNumber from 'bignumber.js';
import { PLATFORM_IDS } from '@/constants';
import { ethers } from 'ethers';
import yEthVaultAbi from '@/abis/yEthVaultAbi';
import yVaultAbi from '@/abis/yVaultAbi';

const COZY_DAI_MARKET_ADDRESS = '0x73269463d5a325aca756ec4dfbac5f1eb81602c9';
const YEARN_DAI_CONTRACT_ADDRESS = '0x19D3364A399d251E894aC732651be8B0E4e85001';

const COZY_ETH_MARKET_ADDRESS = '0xba94c268049dd87ded35f41f6d4c7542b4bdb767';
const YEARN_WETH_CONTRACT_ADDRESS = '0x5A0bade607eaca65A0FE6d1437E0e3EC2144d540';
const YEARN_ETH_CONTRACT_ADDRESS = '0xa9fe4601811213c340e850ea305481aff02f5b28';

const COZY_USDC_MARKET_ADDRESS = '0x37e45483b5242c2b3f35ed153ddaf7ce45c1c7b2';
const YEARN_USDC_V2_MARKET_ADDRESS = '0x5f18C75AbDAe578b483E5F43f12a39cF75b973a9';

export interface YearnApiVault {
  address: string;
  apy: {
    recommended: number;
  };
}

export interface YearnAccountSubgraphData {
  account: {
    vaultPositions: {
      balanceTokens: string;
      latestUpdate: {
        balanceShares: string;
        blockNumber: string;
        sharesMinted: string;
        sharesReceived: string;
        sharesSent: string;
        sharesBurnt: string;
      };
      shareToken: {
        decimals: number;
      };
      vault: {
        id: string;
        latestUpdate: {
          pricePerShare: string;
        };
      };
    }[];
  };
}

export default {
  ethDepositCall: (
    investmentOpportunity: InvestmentOpportunity,
    investParam: string,
    signer: ethers.Signer,
  ): EthereumCall => {
    const { helperContractAddress } = investmentOpportunity;

    const targetContract = new ethers.Contract(helperContractAddress, yEthVaultAbi, signer);

    return {
      data: targetContract.interface.encodeFunctionData('depositETH'),
      requireSuccess: true,
      target: helperContractAddress,
      value: investParam,
    };
  },
  erc20DepositCall: (
    investmentOpportunity: InvestmentOpportunity,
    investParam: string,
    signer: ethers.Signer,
  ): EthereumCall => {
    const { investmentContractAddress } = investmentOpportunity;

    const targetContract = new ethers.Contract(investmentContractAddress, yVaultAbi, signer);

    return {
      data: targetContract.interface.encodeFunctionData('deposit', [investParam]),
      requireSuccess: true,
      target: investmentContractAddress,
      value: 0,
    };
  },
  determineInvestRate: (investmentOpportunity: InvestmentOpportunity, vaults: YearnApiVault[]): number => {
    const vault = vaults?.find((vault) => investmentOpportunity.investmentContractAddress === vault.address);

    return vault.apy.recommended;
  },
  determineInvestBalanceUnderlying: (
    investmentOpportunity: InvestmentOpportunity,
    subgraphData: YearnAccountSubgraphData,
  ): number => {
    const vaultPosition = subgraphData.account?.vaultPositions?.find((position) => {
      return position.vault.id === investmentOpportunity.investmentContractAddress.toLowerCase();
    });

    const latestUpdate = vaultPosition?.latestUpdate;

    if (latestUpdate == null) {
      return null;
    }

    const balanceShares = new BigNumber(latestUpdate.balanceShares).div(
      Math.pow(10, vaultPosition.shareToken.decimals),
    );
    const pricePerShare = new BigNumber(vaultPosition.vault.latestUpdate.pricePerShare).div(
      Math.pow(10, vaultPosition.shareToken.decimals),
    );

    // Calculate via sums of share figures
    // const { sharesMinted, sharesReceived, sharesSent, sharesBurnt } = latestUpdate;
    // const balanceSharesRaw = new BigNumber(sharesMinted).plus(sharesReceived).minus(sharesSent).minus(sharesBurnt);

    // const balanceShares = balanceSharesRaw.div(Math.pow(10, vaultPosition.shareToken.decimals));

    return balanceShares.times(pricePerShare).toNumber();
  },
  determineLastUpdatedBlockNumber: (
    investmentOpportunity: InvestmentOpportunity,
    subgraphData: YearnAccountSubgraphData,
  ): number => {
    const vaultPosition = subgraphData.account?.vaultPositions?.find((position) => {
      return position.vault.id === investmentOpportunity.investmentContractAddress.toLowerCase();
    });

    const blockNumber = vaultPosition?.latestUpdate.blockNumber;

    return blockNumber ? parseInt(blockNumber) : null;
  },
  withdrawCall: (
    investmentOpportunity: InvestmentOpportunity,
    withdrawParams: null,
    signer: ethers.Signer,
  ): EthereumCall => {
    const { investmentContractAddress } = investmentOpportunity;

    const targetContract = new ethers.Contract(investmentContractAddress, yVaultAbi, signer);

    return {
      data: targetContract.interface.encodeFunctionData('withdraw', [ethers.constants.MaxUint256]),
      requireSuccess: true,
      target: investmentContractAddress,
      value: 0,
    };
  },
  investmentOpportunities: [
    {
      cozyMarketAddress: COZY_ETH_MARKET_ADDRESS,
      helperContractAddress: YEARN_WETH_CONTRACT_ADDRESS,
      investmentContractAddress: YEARN_ETH_CONTRACT_ADDRESS,
      platformId: PLATFORM_IDS.Yearn,
    },
    {
      cozyMarketAddress: COZY_DAI_MARKET_ADDRESS,
      investmentContractAddress: YEARN_DAI_CONTRACT_ADDRESS,
      platformId: PLATFORM_IDS.Yearn,
    },
    {
      cozyMarketAddress: COZY_USDC_MARKET_ADDRESS,
      investmentContractAddress: YEARN_USDC_V2_MARKET_ADDRESS,
      platformId: PLATFORM_IDS.Yearn,
    },
  ],
};
