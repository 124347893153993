import { EthereumCall, InvestmentOpportunity } from '@/types';

import { PLATFORM_IDS } from '@/constants';
import { ethers } from 'ethers';
import settAbi from '@/abis/settAbi';

const COZY_WBTC_MARKET_ADDRESS = '0x260836bd6f97d23e4a844abd3b8915e002b00f30';
const BADGER_WBTC_CONTRACT_ADDRESS = '0x4b92d19c11435614cd49af1b589001b7c08cd4d5';

export interface BadgerAccountSubgraphData {
  account: {
    vaultBalances: {
      netDeposits: number;
      vault: { id: string };
    }[];
  };
}

export interface BadgerMarketSubgraphData {
  sources: {
    minApr: number;
  }[];
  vaultToken: string;
}

export default {
  erc20DepositCall: (
    investmentOpportunity: InvestmentOpportunity,
    investParam: string,
    signer: ethers.Signer,
    proxyAddress: string,
  ): EthereumCall => {
    const { investmentContractAddress } = investmentOpportunity;

    const targetContract = new ethers.Contract(investmentContractAddress, settAbi, signer);

    return {
      data: targetContract.interface.encodeFunctionData('depositFor', [proxyAddress, investParam]),
      requireSuccess: true,
      target: investmentContractAddress,
      value: 0,
    };
  },
  determineInvestRate: (
    investmentOpportunity: InvestmentOpportunity,
    subgraphData: BadgerMarketSubgraphData[],
  ): number => {
    const vault = subgraphData.find(
      (vault) => vault.vaultToken.toLowerCase() === investmentOpportunity?.investmentContractAddress.toLowerCase(),
    );

    const res = vault.sources.reduce((acc, source) => acc + source.minApr, 0);

    return res / 100;
  },
  determineInvestBalanceUnderlying: (
    investmentOpportunity: InvestmentOpportunity,
    subgraphData: BadgerAccountSubgraphData,
  ): number => {
    const vaultBalance = subgraphData.account?.vaultBalances?.find(
      (vb) => vb.vault.id === investmentOpportunity?.investmentContractAddress?.toLowerCase(),
    );

    return vaultBalance?.netDeposits;
  },
  determineLastUpdatedBlockNumber: (): // investmentOpportunity: InvestmentOpportunity,
  // subgraphData: BadgerAccountSubgraphData,
  number => {
    // TODO: FIGURE OUT HOW TO GET TIMESTAMP FROM LATEST TRANSACTION ASSOCIATED WITH ACCOUNT
    return null;
  },
  withdrawCall: (
    investmentOpportunity: InvestmentOpportunity,
    withdrawParams: null,
    signer: ethers.Signer,
  ): EthereumCall => {
    const { investmentContractAddress } = investmentOpportunity;

    const targetContract = new ethers.Contract(investmentContractAddress, settAbi, signer);

    return {
      data: targetContract.interface.encodeFunctionData('withdraw'),
      requireSuccess: true,
      target: investmentContractAddress,
      value: 0,
    };
  },
  investmentOpportunities: [
    {
      cozyMarketAddress: COZY_WBTC_MARKET_ADDRESS,
      investmentContractAddress: BADGER_WBTC_CONTRACT_ADDRESS,
      platformId: PLATFORM_IDS.Badger,
    },
  ],
};
