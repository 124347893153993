/**
 * General purpose module to wrap analytics SDK events,
 * may issue events to multiple analytics providers
 */

import { NODE_ENV, PLATFORMS } from '@/constants';

import { Trigger } from '@/types';
import { findPlatformsByTrigger } from '@/helpers/findHelpers';
import queryString from 'query-string';

const PAGE_NAMES = {
  '/activity': { base: 'Recent Activity' },
  ['/']: { base: 'Homepage' },
  '/dashboard': { base: 'Dashboard' },
  '/funds': {
    base: 'Funds',
    0: 'Funds Balance Tab',
    1: 'Funds Deposit Tab',
    2: 'Funds Withdraw Tab',
    3: 'Funds Activity Tab',
  },
  '/borrow': {
    base: 'Borrow',
    0: 'Borrow Balance Tab',
    1: 'Borrow Tab',
    2: 'Pay Back Tab',
    3: 'Borrow Activity Tab',
  },
  '/protected-borrow': {
    base: 'Protected Borrowing',
    0: 'Protected Borrow Balance Tab',
    1: 'Protected Borrow Tab',
    2: 'Protected Pay Back Tab',
    3: 'Protected Borrow Activity Tab',
  },
  '/provide': {
    base: 'Provide Protection',
    0: 'Provide Balance Tab',
    1: 'Provide Deposit Tab',
    2: 'Provide Withdraw Tab',
    3: 'Provide Activity Tab',
  },
  '/protected-invest': {
    base: 'Protected Investing',
    0: 'Earnings Tab',
    1: 'Invest Tab',
    2: 'Invest Withdraw Tab',
    3: 'Invest Activity Tab',
  },
};

export const trackPage = (url: string): void => {
  const pageName = determinePageData(url);

  window.analytics.page(pageName);
};

export const trackEvent = (name: string, properties: Record<string, unknown> = {}): void => {
  properties = { ...properties, env: NODE_ENV };

  window.analytics.track(name, properties);
};

export const triggerAnalyticsProps = (
  trigger: Trigger,
): { creatorAddress?: string; creatorName?: string; description?: string; name?: string; platforms?: string[] } => {
  if (trigger == null) return {};

  const { creator, description, name, creatorAddress } = trigger;
  const platforms = findPlatformsByTrigger(PLATFORMS, trigger);

  return {
    creatorAddress,
    creatorName: creator?.name,
    description,
    name,
    platforms: platforms.map((p) => p.name),
  };
};

const determinePageData = (rawUrl: string): string => {
  const parsed = queryString.parseUrl(rawUrl);

  const { url, query } = parsed;

  const tab = query?.tab ?? 'base';

  if (PAGE_NAMES[url]) {
    return PAGE_NAMES[url][tab];
  } else {
    return url;
  }
};

export const capitalizeFirstLetter = (event: string): string => {
  return event[0].toUpperCase() + event.slice(1);
};
