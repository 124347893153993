// Bring public env variables out of process and into js scope
export const FULL_STORY_ORG_ID = process.env.NEXT_PUBLIC_FULL_STORY_ORG_ID;
export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID;
export const SEGMENT_WRITE_KEY = process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY;

export const PORTIS_DAPP_ID = process.env.NEXT_PUBLIC_PORTIS_DAPP_ID;
export const FORTMATIC_API_KEY = process.env.NEXT_PUBLIC_FORTMATIC_API_KEY;

export const NODE_ENV = process.env.NODE_ENV;

// Market data for this chain will be preloaded on server side proir to checking what chain the active
// wallet has loaded. Another call will be made once the front-end loads and checks chain of active  wallet.
export const DEFAULT_CHAIN_ID = 1;

// Ethereum related constants
export const ETH_TOKEN_CONTRACT_ADDRESS = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee';
export const ETHEREUM_BURN_ADDRESS = '0x0000000000000000000000000000000000000000';
export const ETH_NETWORKS = {
  1: 'mainnet',
  3: 'ropsten',
  4: 'rinkeby',
  42: 'kovan',
  1337: 'http://localhost:8545',
};
export const ETH_NETWORKS_DISPLAY_NAMES = {
  1: 'Mainnet',
  3: 'Ropsten Network',
  4: 'Rinkeby Network',
  42: 'Kovan Network',
  1337: 'Localhost',
};

// Cozy Infura urls used to power wallet and get default providers for Ethereum data
export const PUBLIC_NETWORK_URL = {
  1: 'https://mainnet.infura.io/v3/f49cbb17ebc0415380fbea587b536db3',
  3: 'https://ropsten.infura.io/v3/f49cbb17ebc0415380fbea587b536db3',
  4: 'https://rinkeby.infura.io/v3/f49cbb17ebc0415380fbea587b536db3',
  42: 'https://kovan.infura.io/v3/f49cbb17ebc0415380fbea587b536db3',
  1337: 'https://mainnet.infura.io/v3/f49cbb17ebc0415380fbea587b536db3',
};

// Address of the DS Proxy registry used to check and build users' proxy wallet
export const PROXY_REGISTRY_ADDRESS = {
  1: '0x4678f0a6958e4D2Bc4F1BAF7Bc52E8F3564f3fE4',
  4: '0x4ceeb165578f17b15fdf055991b22e3d7d181a08',
  1337: '0x4678f0a6958e4D2Bc4F1BAF7Bc52E8F3564f3fE4',
};

// Cozy subgraph used to power most of the data in app
export const SUBGRAPH_URLS = {
  1: 'https://api.thegraph.com/subgraphs/name/cozy-finance/cozy-v0',
  4: 'https://api.thegraph.com/subgraphs/name/dpekar/cozy-subgraph-dev',
  1337: 'http://127.0.0.1:8000/subgraphs/name/Cozy-Finance/cozy-subgraph',
};

// Third party subgraphs used for powering Cozy Invest
export const YEARN_SUBGRAPH_URLS = {
  1: 'https://api.thegraph.com/subgraphs/name/salazarguille/yearn-vaults-v2-subgraph-mainnet',
  1337: 'https://api.thegraph.com/subgraphs/name/salazarguille/yearn-vaults-v2-subgraph-mainnet',
};
export const YEARN_API_URLS = {
  1: 'https://vaults.finance/all',
  1337: 'https://vaults.finance/all',
};
export const COMPOUND_SUBGAPH_URLS = {
  1: 'https://api.thegraph.com/subgraphs/name/graphprotocol/compound-v2',
  4: 'https://api.thegraph.com/subgraphs/id/QmXNqdekS39uVaubGg3pjh4W6uD1xgX62bwDrGQjVy5iv3',
};
export const BADGER_SUBGRAPH_URLS = {
  1: 'https://api.thegraph.com/subgraphs/name/darruma/badger-setts',
  1337: 'https://api.thegraph.com/subgraphs/name/darruma/badger-setts',
};
export const BADGER_API_URL = {
  1: 'https://api.badger.finance/v2/setts?chain=eth',
  1337: 'https://api.badger.finance/v2/setts?chain=eth',
};

// Links used in footer and buttons throughout app
export const COZY_DISCORD_URL = 'https://discord.gg/SuGY9vtz8u';
export const COZY_DEVELOPER_DOCS_URL = 'https://docs.cozy.finance';
export const COZY_GITHUB_URL = 'https://github.com/Cozy-Finance/cozy-protocol';
export const COZY_TWITTER_URL = ' https://twitter.com/cozyfinance';
export const COZY_JOBS_URL = 'https://www.notion.so/cozyfinance/Work-at-Cozy-0419046da0774b929123b480ad17317e';

export enum PLATFORM_IDS {
  Yearn = '1',
  Aave = '2',
  Curve = '3',
  Compound = '4',
  Uniswap = '5',
  Badger = '6',
}

// Lists of metadata maintained by the front-end that help with presentation
export const PLATFORMS = [
  {
    id: PLATFORM_IDS.Yearn,
    name: 'Yearn',
    logo: 'yearn.png',
    url: 'https://yearn.finance/dashboard',
  },
  {
    id: PLATFORM_IDS.Aave,
    name: 'Aave',
    logo: 'aave.png',
    url: 'https://app.aave.com/',
  },
  {
    id: PLATFORM_IDS.Curve,
    name: 'Curve',
    logo: 'curve.png',
    url: 'https://www.curve.fi/',
  },
  {
    id: PLATFORM_IDS.Compound,
    name: 'Compound',
    logo: 'compound.png',
    url: 'https://app.compound.finance/',
  },
  {
    id: PLATFORM_IDS.Uniswap,
    name: 'Uniswap',
    logo: 'uniswap.png',
    url: 'https://app.uniswap.org/#/swap',
  },
  {
    id: PLATFORM_IDS.Badger,
    name: 'Badger',
    logo: 'badger.jpg',
    url: 'https://https://app.badger.finance/',
  },
];

export const TRIGGER_CREATORS = [
  {
    address: '0x7c4202f3b6631cfd4a60f47fcdeae6ed5d1a923b',
    name: 'Matt',
    logo: 'quantstamp.png',
    url: 'https://quantstamp.com/',
  },
  {
    address: '0xf07fa9f3d21fa32fad1b02f27ea4d8d2e64931a0',
    name: 'Electric Capital',
    logo: 'electric.png',
    url: 'https://www.electriccapital.com/',
  },
];

export const SYBIL_VERIFIED_LIST_URL = 'https://raw.githubusercontent.com/Uniswap/sybil-list/master/verified.json';
export const COZY_SITE_URL = 'https://cozy.finance';
export const MIGRATION_DOCS_URL = 'https://docs.cozy.finance/cozy/faq#why-am-i-being-asked-to-migrate-my-positions';
