import '@chakra-ui/react';

import { BigNumberish, ethers } from 'ethers';
import { CloseAllToastsOptions, ToastId, UseToastOptions } from '@chakra-ui/react';

import BlocknativeSdk from 'bnc-sdk';

// INTERFACES

export interface Account {
  id?: string;
  activityItems?: ActivityItem[];
  positions?: Position[];
  proxyAddress: string;
  totalBorrowValueInEth?: number;
  totalCollateralValueInEth?: number;
  totalBorrowValueInUsd?: number;
  totalCollateralValueInUsd?: number;
}

export interface Comptroller {
  address: string;
  id: string;
  maximillionAddress: string;
  multicallAddress: string;
  priceOracle: string;
}

export interface Ethereum {
  isMetaMask: boolean;
  enable: () => Promise<string[]>;
  on?: (method: string, listener: (...args: any[]) => void) => void;
  removeListener?: (method: string, listener: (...args: any[]) => void) => void;
  send: unknown;
}

export interface EthereumCall {
  target: string; // target contract
  data: string; // calldata to send on call to target contract
  value: BigNumberish; // value to send with the call
  requireSuccess: boolean; // true if whole tx should revert on failure, false if we ignore when this call fails (useful when claiming rewards tokens)
}

export interface EthereumTransaction {
  blockNumber?: number;
  eventCode?: string;
  from?: string;
  fromAccount?: string;
  hash: string;
  id: string;
  productAddress?: string;
  startTime?: number;
  status: string;
  type?: string;
  uiSynced?: boolean;
  marketId?: string;
}

export interface InvestmentPlatform {
  ethDepositCall?: (
    investmentOpportunity: InvestmentOpportunity,
    investParam: string,
    signer: ethers.Signer,
  ) => EthereumCall;
  erc20DepositCall: (
    investmentOpportunity: InvestmentOpportunity,
    investParam: string,
    signer: ethers.Signer,
    proxyAddress?: string,
  ) => EthereumCall;
  determineInvestRate: (investmentOpportunity: InvestmentOpportunity, subgraphData) => number;
  investmentOpportunities: InvestmentOpportunity[];
  determineInvestBalanceUnderlying: (InvestmentOpportunity, data, market?: Market) => number;
  determineLastUpdatedBlockNumber: (InvestmentOpportunity, data, market?: Market) => number;
  withdrawCall: (
    investmentOpportunity: InvestmentOpportunity,
    withdrawParam: string | null,
    signer: ethers.Signer,
  ) => EthereumCall;
}

export interface InvestmentOpportunity {
  cozyMarketAddress: string;
  helperContractAddress?: string;
  investmentContractAddress: string;
  platformId: string;
}

export interface Market {
  borrowCap: number;
  borrowRate: number;
  cash: number;
  collateralFactor: string;
  decimals: number;
  exchangeRate: number;
  id: string;
  investRate?: number;
  interestRateModelAddress: string;
  name: string;
  supplyRate: number;
  symbol: string;
  totalBorrows: number;
  totalSupply: number;
  triggerAddress: string | null;
  trigger: Trigger;
  underlyingAddress: string;
  underlyingDecimals: number;
  underlyingName: string;
  underlyingPrice: number;
  underlyingPriceUSD: number;
  underlyingSymbol: string;
}

export interface Platform {
  id: string;
  logo: string;
  name: string;
  url?: string;
}

export interface Position {
  accrualBlockNumber: string;
  borrowBalanceUnderlying: number;
  cTokenBalance: number;
  enteredMarket: boolean;
  id: string;
  investBalanceUnderlying: number;
  investLastUpdatedBlockNumber?: number;
  lifetimeBorrowInterestAccrued: number;
  lifetimeSupplyInterestAccrued: number;
  market: Market;
  marketId: string;
  supplyBalanceUnderlying: number;
  symbol: string;
  totalUnderlyingBorrowed: number;
  totalUnderlyingRedeemed: number;
  totalUnderlyingSupplied: number;
}

export interface TriggerCreator {
  address: string;
  name: string;
  logo: string;
  url: string;
}

export interface TransactionContextInterface {
  blocknative: BlocknativeSdk;
  setBlocknative: React.Dispatch<React.SetStateAction<BlocknativeSdk>>;
  setTransactions: React.Dispatch<React.SetStateAction<EthereumTransaction[]>>;
  toast: Toast;
  transactions: EthereumTransaction[];
}

export interface Trigger {
  address: string;
  creatorAddress: string;
  creator: TriggerCreator;
  description: string;
  id: string;
  name: string;
  platformIds: string[];
  triggered: boolean;
  triggeredAt: number;
}

export interface ValueProp {
  imageUrl: string;
  heading: string;
  body: string;
}

// EVENT TYPES

export enum EventTypes {
  BorrowEvent = 'borrowEvent',
  LiquidationEvent = 'liquidationEvent',
  MintEvent = 'mintEvent',
  RedeemEvent = 'redeemEvent',
  RepayEvent = 'repayEvent',
  TriggerEvent = 'triggerEvent',
}

export interface ActivityItem {
  blockTime: number;
  event: BorrowEvent | LiquidationEvent | MintEvent | RedeemEvent | RepayEvent | TriggerEvent;
  eventType: EventTypes;
  marketType: MarketTypes;
  platforms?: Platform[];
  usdAmount: number;
}

export interface Event {
  amount: number;
  blockNumber: number;
  blockTime: number;
  id: string;
  marketId: string;
  underlyingAmount: number;
  underlyingSymbol: string;
}

export interface BorrowEvent extends Event {
  borrower: string;
}

export interface LiquidationEvent extends Event {
  cTokenSymbol: string;
  fromAddress: string;
  id: string;
  to: string;
  underlyingRepayAmount: number;
}

export interface MintEvent extends Event {
  cTokenSymbol: string;
  fromAddress: string;
  to: string;
}

export interface RedeemEvent extends Event {
  cTokenSymbol: string;
  fromAddress: string;
  id: string;
  to: string;
}

export interface RepayEvent extends Event {
  borrower: string;
  payer: string;
}

export interface TriggerEvent extends Event {
  positionId: string;
  positionType?: PositionTypes;
}

// ENUMS

export enum ButtonType {
  Primary,
  Secondary,
  Link,
  Ghost,
}

export enum EthereumTransactionTypes {
  Deposit = 'deposit',
  Redeem = 'redeem',
  Borrow = 'borrow',
  Repay = 'repay',
  Approve = 'approve',
  Invest = 'invest',
  ProxyWallet = 'proxy wallet',
  Withdraw = 'withdraw',
}

export enum CozyEvents {
  Borrow = 'Borrow',
  RepayBorrow = 'RepayBorrow',
  Mint = 'Mint',
  Redeem = 'Redeem',
}

export enum MarketTypes {
  NonProtectionMarket,
  ProtectionMarket,
  InvestmentMarket,
}

export enum PositionTypes {
  Borrow = 'borrow',
  Supply = 'supply',
  BorrowAndSupply = 'borrowAndSupply',
  Invest = 'invest',
}

export enum ProtectionRowLayout {
  Default,
  Alternate,
}

export enum TransactionStatuses {
  Sent = 'sent',
  Pending = 'pending',
  Confirmed = 'confirmed',
  Failed = 'failed',
  Cancel = 'cancel',
}

// THIRD PARTY

export type Toast = {
  (options?: UseToastOptions): string | number;
  close: (id: string | number) => void;
  closeAll: (options?: CloseAllToastsOptions) => void;
  update(
    id: ToastId,
    options: Pick<
      UseToastOptions,
      | 'position'
      | 'onCloseComplete'
      | 'duration'
      | 'title'
      | 'status'
      | 'render'
      | 'description'
      | 'isClosable'
      | 'variant'
    >,
  ): void;
  isActive: (id: string | number) => boolean;
};
